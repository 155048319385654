import UserAccessDropdown from '@components/Shared/Dropdown/UserAccessDropdown'
import UserDropdown from '@components/Shared/Dropdown/UserDropdown'
import { Button } from '@components/Ui'
import { BUTTON_SIZE, BUTTON_TYPE } from '@components/Ui/Button/Button'
import { User } from '@lib/types/user'
import useTranslations from '@locales/useTranslations'
import { useState } from 'react'

interface IAsignAnotherOwner {
  owner: User
  onClose: Function
  onOwnerChange: Function
}

const AssignAnotherOwner = ({
  owner,
  onClose,
  onOwnerChange,
}: IAsignAnotherOwner) => {
  const [selectedUser, setSelectedUser] = useState(null)

  const { t } = useTranslations()

  return (
    <div className="bg-white shadow-lg px-4 py-3 rounded-md min-w-[296px]">
      <span className="text-sm text-oxford-gray-800">
        {t?.('expressions.assignAnotherOwner')}
      </span>
      <div className="my-3">
        <UserDropdown
          value={selectedUser}
          onChange={(user) => setSelectedUser(user)}
          dataParser={(data) => data.filter((user) => user.id !== owner.id)}
          placeholder={t?.('expressions.chooseUser')}
          renderClearButton={false}
        />
      </div>

      <div className="flex justify-between">
        <Button
          size={BUTTON_SIZE.SMALL}
          onClick={() => {
            onOwnerChange(selectedUser)
            onClose()
          }}
        >
          {t?.('buttons.assignOwner')}
        </Button>
        <Button
          size={BUTTON_SIZE.SMALL}
          type={BUTTON_TYPE.TEXT}
          onClick={onClose}
        >
          {t?.('buttons.cancel')}
        </Button>
      </div>
    </div>
  )
}

export default AssignAnotherOwner
