import { ApolloLink, createHttpLink, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { SessionExtended } from '@lib/types/nextauth'
import { FragmentDefinitionNode, OperationDefinitionNode } from 'graphql'
import { ApolloConfig } from './apollo'

const isMutationOperation = (operation: any) =>
  operation.query?.definitions?.reduce(
    (result: boolean, definition: OperationDefinitionNode) =>
      result || definition.operation === 'mutation',
    false,
  )

const isSubscriptionOperation = (
  definition: OperationDefinitionNode | FragmentDefinitionNode,
) =>
  definition.kind === 'OperationDefinition' &&
  definition.operation === 'subscription'

export const httpLink = createHttpLink({
  credentials: 'same-origin',
  uri: process.env.NEXT_PUBLIC_API_URL,
})

export const createSplitLink = (session: ApolloConfig['session']) => {
  let activeSocket
  let timedOut

  const wsLink = new GraphQLWsLink(
    createClient({
      url: process.env.NEXT_PUBLIC_WS_URL,
      connectionParams: {
        Authorization: session?.bearerToken,
      },
      keepAlive: 50_000,
      shouldRetry: () => true,
      retryAttempts: Infinity,
      on: {
        connected: (socket) => (activeSocket = socket),
        ping: (received) => {
          if (!received)
            timedOut = setTimeout(() => {
              if (activeSocket.readyState === WebSocket.OPEN)
                activeSocket.close(4408, 'Request Timeout')
            }, 5_000)
        },
        pong: (received) => {
          if (received) clearTimeout(timedOut)
        },
      },
    }),
  )

  return split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return isSubscriptionOperation(definition)
    },
    wsLink,
    httpLink,
  )
}
export const cleanTypeNameLink = new ApolloLink((operation, forward) => {
  if (operation.variables && isMutationOperation(operation)) {
    const omitTypename = (key: string, value: any) =>
      key === '__typename' ? undefined : value
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename,
    )
  }
  return forward(operation)
})

export const createAuthLink = ({
  session,
}: { session?: SessionExtended } = {}) => {
  return setContext((_, { headers }) => {
    const additionalHeaders = session
      ? {
          Authorization: session.bearerToken,
        }
      : {}

    return {
      headers: {
        ...headers,
        ...additionalHeaders,
      },
    }
  })
}
