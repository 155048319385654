import { AVATAR_COLORS } from '@utils/constants'
import React, { CSSProperties } from 'react'
import Image from 'next/image'
import classNames from 'classnames'
import { UUID } from '@lib/types/general'
import { useFragment } from '@apollo/client'
import { AccountProfileImageFragmentFragment } from '@lib/graphql/__generated__/graphql'
import { ACCOUNT_PROFILE_IMAGE_FRAGMENT } from '@lib/graphql/fragments/Organization'

export interface IUserAvatarProps {
  src: string
  name: string
  colors?: string[]
  size?: number | string
  style?: any
  className?: string
  innerClassName?: string
}

const getInitials = (fullName) => {
  const allNames = fullName.trim().split(' ')
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`
    }
    return acc
  }, '')
  return initials
}

const sumChars = (str) => {
  let sum = 0
  for (let i = 0; i < str.length; i++) {
    sum += str.charCodeAt(i)
  }

  return sum
}

const getPx = (size) => `${size}px`

export const UserAvatarBase = (props: IUserAvatarProps) => {
  let {
    src,
    name,
    colors = AVATAR_COLORS,
    size = 40,
    style,
    className,
    innerClassName,
  } = props

  if (!name) {
    name = 'User'
  }

  const abbr = getInitials(name)
  const sizeInPx = getPx(size)

  const imageStyle: CSSProperties = {
    display: 'block',
    width: sizeInPx,
    height: sizeInPx,
    borderRadius: '100%',
  }

  const innerStyle: CSSProperties = {
    width: sizeInPx,
    height: sizeInPx,
    maxWidth: sizeInPx,
    maxHeight: sizeInPx,
    lineHeight: sizeInPx,
    backgroundColor: '',
    borderRadius: '100%',
  }

  let inner
  let classes = [className, 'relative user-avatar']
  if (src) {
    inner = (
      <div className="overflow-hidden relative" style={imageStyle}>
        <Image
          unoptimized
          style={{
            objectFit: 'cover',
          }}
          fill
          src={src}
          alt={name}
          sizes={`${size}px`}
        />
      </div>
    )
  } else {
    let background

    // pick a deterministic color from the list
    let i = sumChars(name) % colors.length

    background = colors[i]

    innerStyle.backgroundColor = background

    inner = abbr
  }

  const sizeNumber = +size

  return (
    <div aria-label={name} className={classNames(classes)} style={style}>
      <div
        style={innerStyle}
        className={classNames([
          'flex justify-center items-center',
          innerClassName,
        ])}
      >
        <span
          className={classNames([
            'font-semibold text-oxford-gray-500',
            {
              'text-2xs': sizeNumber <= 24,
              'text-xs': sizeNumber > 24 && sizeNumber <= 30,
              'text-sm': sizeNumber > 30 && sizeNumber <= 50,
              'text-lg': sizeNumber > 50,
            },
          ])}
        >
          {inner}
        </span>
      </div>
    </div>
  )
}

const UserAvatarWrapper = ({
  accountId,
  ...rest
}: Omit<IUserAvatarProps, 'src'> & { accountId: UUID }) => {
  const { data: account } = useFragment<AccountProfileImageFragmentFragment>({
    from: {
      __typename: 'Account',
      id: accountId,
    },
    fragment: ACCOUNT_PROFILE_IMAGE_FRAGMENT,
    fragmentName: 'AccountProfileImageFragment',
  })

  return <UserAvatarBase {...rest} src={account?.profileImage?.presignedUrl} />
}

export default UserAvatarWrapper
