import useTranslations from '@locales/useTranslations'
import { CONTRIBUTOR_RIGHTS } from '@utils/constants'
import UserContributor from '../AccessComponents/UserContributor'
import { User } from '@lib/types/user'

export interface IProjectPublicTab {
  resourceOwner: User
  onOwnerChange: Function
}

const ProjectPublicTab = ({
  resourceOwner,
  onOwnerChange,
}: IProjectPublicTab) => {
  const { t } = useTranslations()

  return (
    resourceOwner && (
      <UserContributor
        account={resourceOwner}
        access={CONTRIBUTOR_RIGHTS(t).OWNER.value}
        onOwnerChange={onOwnerChange}
      />
    )
  )
}

export default ProjectPublicTab
