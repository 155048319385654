import { BaseMutationOptions, MutationTuple, useMutation } from '@apollo/client'
import { DocumentNode } from 'graphql'

export interface UseSaveMutationOptions {
  mutation: DocumentNode
  updateQuery: DocumentNode
  updateQueryVariables?: Record<any, any>
  updateResolver: (queryData: any, result: any) => any
  options?: BaseMutationOptions<any, any>
}

const useSaveMutation = ({
  mutation,
  updateQuery,
  updateQueryVariables,
  options = {},
  updateResolver,
}: UseSaveMutationOptions) => {
  const [save, result] = useMutation(mutation, {
    update: (cache, result) => {
      cache.updateQuery(
        { query: updateQuery, variables: updateQueryVariables },
        (queryData) => updateResolver(queryData, result),
      )
    },
    ...options,
  })

  return [save, result] as [typeof save, any]
}

export default useSaveMutation
