import UserDropdown from '@components/Shared/Dropdown/UserDropdown'
import UserContributor from '../AccessComponents/UserContributor'
import { useFormikContext } from 'formik'
import { CONTRIBUTOR_RIGHTS, CONTRIBUTOR_TYPE } from '@utils/constants'
import useTranslations from '@locales/useTranslations'
import { User } from '@lib/types/user'
import useContributorActions from '@lib/hooks/useContributorActions'

export interface IPrivateTab {
  resourceOwner: User
  onOwnerChange: Function
}

const PrivateTab = ({ resourceOwner, onOwnerChange }: IPrivateTab) => {
  const { values }: any = useFormikContext()

  const { t } = useTranslations()

  const { OWNER } = CONTRIBUTOR_RIGHTS(t)

  const { onContributorAdd, ...rest } = useContributorActions(
    CONTRIBUTOR_TYPE.USER,
    resourceOwner,
  )

  return (
    <>
      {resourceOwner && (
        <UserContributor
          key={resourceOwner.id}
          account={resourceOwner}
          access={OWNER.value}
          onOwnerChange={onOwnerChange}
        />
      )}

      {!!values?.userContributors?.length &&
        values.userContributors.map(({ account, access, ...restUser }) => (
          <UserContributor
            key={account.id}
            account={account}
            access={access}
            {...restUser}
            {...rest}
          />
        ))}

      <UserDropdown
        placeholder={t('buttons.addUser')}
        onChange={onContributorAdd}
        dataParser={(data) =>
          data.filter(
            (user) =>
              user.id !== resourceOwner.id &&
              !values.userContributors.find(
                ({ account }) => user.id === account.id,
              ),
          )
        }
      />
    </>
  )
}

export default PrivateTab
