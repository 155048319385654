import React, { useTransition, useState, useEffect } from 'react'
import isEmpty from 'ramda/src/isEmpty'
import toLower from 'ramda/src/toLower'
import { Listbox, Transition } from '@headlessui/react'
import SearchBox from '../../SearchBox'
import MultiselectOption from './MultiselectOption'
import classNames from 'classnames'
import type { IMultiselectProps } from '../Multiselect'
import type { Placement } from '@popperjs/core'
import DropdownListNoDataOption from '@components/Ui/Dropdown/List/DropdownListNoDataOption'
import DropdownListCreateOption from '@components/Ui/Dropdown/List/DropdownListCreateOption'

export interface IMultiselectListProps extends Partial<IMultiselectProps> {
  popperStyles?: { [key: string]: React.CSSProperties }
  popperAttributes?: { [key: string]: { [key: string]: string } | undefined }
  popperPlacement?: Placement | null
  setPopperElement?: React.Ref<HTMLDivElement>
}

const initialFilterState = ''

const MultiselectList = ({
  data,
  loading,
  valueField,
  textField,
  value,
  renderItem,
  allowCreate,
  isItemRemovable,
  popperPlacement,
  setPopperElement,
  popperStyles,
  popperAttributes,
}: IMultiselectListProps) => {
  const [_, startTransition] = useTransition()
  const [filter, setFilter] = useState(initialFilterState)

  if (loading && (!data || isEmpty(data))) {
    return null
  }

  if (filter) {
    data = data.filter((dataItem) =>
      toLower(dataItem[textField]).includes(toLower(filter.trim())),
    )
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      setFilter('')
    }
  }

  return (
    <Transition
      as={React.Fragment}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Listbox.Options
        static
        ref={setPopperElement}
        onKeyDown={onKeyDown}
        style={popperStyles.popper}
        {...popperAttributes.popper}
        className={classNames([
          'absolute z-20 flex flex-col py-1 w-full max-h-[250px] bg-white border border-oxford-gray-100 shadow-lg',
          {
            'rounded-b-md': popperPlacement && popperPlacement !== 'top',
            'rounded-t-md': popperPlacement && popperPlacement === 'top',
          },
        ])}
      >
        <React.Fragment>
          <SearchBox
            value={filter}
            onChange={(filter) => startTransition(() => setFilter(filter))}
          />
          <div className="flex flex-col overflow-auto">
            {data && !isEmpty(data) ? (
              data.map((dataItem) => {
                if (
                  typeof dataItem?.isActive === 'boolean' &&
                  dataItem?.isActive === false
                ) {
                  return null
                }

                return (
                  <MultiselectOption
                    key={dataItem[valueField]}
                    dataItem={dataItem}
                    textField={textField}
                    valueField={valueField}
                    value={value}
                    renderItem={renderItem}
                    disabled={!isItemRemovable?.({ item: dataItem, data })}
                    onClick={() => {
                      setFilter(initialFilterState)
                    }}
                  />
                )
              })
            ) : (
              <DropdownListNoDataOption />
            )}

            {allowCreate &&
              !!filter.trim().length &&
              !data.find(
                (dataItem) =>
                  toLower(dataItem[textField]) === toLower(filter.trim()),
              ) && (
                <DropdownListCreateOption
                  filter={filter}
                  textField={textField}
                  setFilter={setFilter}
                />
              )}
          </div>
        </React.Fragment>
      </Listbox.Options>
    </Transition>
  )
}

export default MultiselectList
