import { ReactNode } from 'react'

interface IContributorContainer {
  title: string
  contributors: ReactNode
}

const ContributorContainer = ({
  contributors,
  title,
}: IContributorContainer) => {
  return (
    <>
      <div className="relative z-10 border-t border-1 border-oxford-gray-200 mx-[-16px]">
        <div className="px-3 py-3">
          <div className="text-oxford-gray-400 uppercase mb-2 text-xs">
            {title}
          </div>
          {contributors}
        </div>
      </div>
    </>
  )
}

export default ContributorContainer
