import UserAccessDropdown from '@components/Shared/Dropdown/UserAccessDropdown'
import UserAvatar from '@components/Shared/UserAvatar/UserAvatar'
import { UUID } from '@lib/types/general'
import { User } from '@lib/types/user'
import ContributorMoreActions from './ContributorMoreActions'
import useTranslations from '@locales/useTranslations'
import { CONTRIBUTOR_RIGHTS } from '@utils/constants'
import OwnerMoreActions from './OwnerMoreActions'

interface IUserContributorProps {
  account: User
  access: string
  teamId?: UUID
  disabled?: boolean
  showMoreActions?: boolean
  isProjectResponsible?: boolean
  onPermissionChange?: Function
  onContributorDelete?: Function
  onOwnerChange?: Function
}

const UserContributor = ({
  account,
  access,
  teamId,
  showMoreActions = true,
  disabled = false,
  onPermissionChange,
  onContributorDelete,
  isProjectResponsible,
  onOwnerChange,
}: IUserContributorProps) => {
  const { t } = useTranslations()

  const contributorRights = CONTRIBUTOR_RIGHTS(t)

  const { id: userId, fullName, jobTitle } = account

  const isOwner = contributorRights.OWNER.value === access

  const actionProps = teamId ? { userId, teamId } : { userId }

  const shouldDisable = disabled || isOwner || isProjectResponsible
  const actionItems = [
    {
      id: 0,
      label: <span className="text-watermelon-500">{t('buttons.delete')}</span>,
      action: () => onContributorDelete && onContributorDelete(actionProps),
    },
  ]

  return (
    <div className="flex justify-between mt-2 mb-3">
      <div className="flex items-center w-2/3 mr-2">
        <div className="mr-2">
          <UserAvatar name={fullName} accountId={userId} />
        </div>
        <div className="flex flex-col font-sm text-oxford-gray-800">
          <span className="font-semibold text-sm truncate">{fullName}</span>
          <span className="text-xs">{jobTitle?.name}</span>
        </div>

        <div className="ml-2">
          {isProjectResponsible && (
            <span className="text-white bg-[#00b56f] rounded-md p-1 text-xs">
              {t('access.projectOwner')}
            </span>
          )}
          {access === 'OWNER' && (
            <span className="text-white bg-[#9fbbf1] rounded-md p-1 text-xs">
              {t('access.owner')}
            </span>
          )}
        </div>
      </div>

      <div className="flex">
        <div className="flex justify-center items-center mr-3">
          <div className="min-w-[150px]">
            <UserAccessDropdown
              allowSearch={false}
              value={
                isOwner || isProjectResponsible
                  ? contributorRights.EDITOR
                  : contributorRights[access]
              }
              onChange={({ value }) =>
                onPermissionChange({ value, ...actionProps })
              }
              dataParser={(data) =>
                data.filter(
                  ({ value }) =>
                    value !== access && value !== contributorRights.OWNER.value,
                )
              }
              disabled={shouldDisable}
            />
          </div>
        </div>
        {showMoreActions && !shouldDisable && (
          <div className="flex justify-center items-center h-100">
            {isOwner ? (
              <OwnerMoreActions
                owner={account}
                onOwnerChange={onOwnerChange}
                disabled={disabled}
              />
            ) : (
              <ContributorMoreActions
                actionItems={actionItems}
                disabled={disabled || isProjectResponsible}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default UserContributor
