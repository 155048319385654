import React, { useCallback } from 'react'
import classnames from 'classnames'
import { Listbox } from '@headlessui/react'
import Spinner from '@components/Shared/Loaders/Spinner'
import type { IDropdown } from '../Dropdown'
import type { Placement } from '@popperjs/core'
import useTranslations from '@locales/useTranslations'

interface IDropdownButton extends IDropdown {
  open: boolean
  popperPlacement: Placement | null
  setButtonElement: Function
}

const theme = {
  button: {
    shared:
      'relative w-100 rounded-md border data-[headlessui-state=open]:border-electric-violet-500',
    default: 'border-oxford-gray-200',
    error: 'border-watermelon-800',
    disabled: 'bg-oxford-gray-50 border-[#E5E7EB]',
  },
  buttonLabel: {
    shared: 'block truncate text-sm leading-[1.9rem]',
    default: 'text-oxford-gray-800',
    error: 'text-watermelon-900',
    placeholder: 'text-oxford-gray-300',
    disabled: 'text-oxford-gray-400',
  },
}

export const DropdownButtonIcon = ({
  loading,
  value,
  disabled,
  renderClearButton,
  onClear,
}) => {
  if (typeof renderClearButton === 'function' && !!value && !disabled) {
    return renderClearButton(onClear)
  }

  return (
    <span className="absolute inset-y-0 right-0 px-3 flex items-center pointer-events-none">
      {loading ? (
        <Spinner />
      ) : (
        <i
          className={classnames([
            'fas fa-caret-down text-sm',
            {
              'text-oxford-gray-800': !disabled,
              'text-oxford-gray-200': disabled,
            },
          ])}
          aria-hidden="true"
        />
      )}
    </span>
  )
}

const DropdownButton = ({
  id = '',
  name = '',
  className,
  value,
  placeholder,
  renderItem,
  textField = 'name',
  paddingClass = 'py-2 px-3',
  disabled,
  open,
  loading,
  popperPlacement,
  error,
  onChange,
  onBlur,
  renderClearButton,
  renderButtonContent,
  setButtonElement,
}: IDropdownButton) => {
  const { t } = useTranslations()

  if (!placeholder) {
    placeholder = t('expressions.select')
  }

  const onClear = useCallback(() => onChange(null), [onChange])

  return (
    <Listbox.Button
      ref={(newRef) => {
        if (newRef) {
          setButtonElement((ref) => {
            if (ref) {
              return ref
            }

            return newRef
          })
        }
      }}
      onBlur={onBlur}
      className={classnames([
        theme.button.shared,
        {
          [theme.button.default]: !error && !disabled,
          [theme.button.error]: !!error && !disabled,
          [theme.button.disabled]: disabled,
          'data-[headlessui-state=open]:rounded-b-none':
            popperPlacement && popperPlacement !== 'top',
          'data-[headlessui-state=open]:rounded-t-none':
            popperPlacement && popperPlacement === 'top',
        },
        className,
      ])}
      name={name}
      id={id}
    >
      {value && typeof renderItem === 'function' ? (
        renderItem({ item: value, inButton: true })
      ) : typeof renderButtonContent === 'function' ? (
        renderButtonContent({
          item: value,
          placeholder,
        })
      ) : (
        <span className={classnames('flex items-center', paddingClass)}>
          <span
            className={classnames([
              theme.buttonLabel.shared,
              {
                [theme.buttonLabel.default]: !error && !disabled && value,
                [theme.buttonLabel.error]: !!error && !open,
                [theme.buttonLabel.disabled]: disabled,
                [theme.buttonLabel.placeholder]:
                  !!placeholder && !error && !disabled && !value,
              },
            ])}
          >
            {value?.[textField] ?? placeholder}
          </span>
        </span>
      )}

      <DropdownButtonIcon
        loading={loading}
        value={value}
        disabled={disabled}
        onClear={onClear}
        renderClearButton={renderClearButton}
      />
    </Listbox.Button>
  )
}

export { theme as dropdownButtonTheme }

export default DropdownButton
