import { Transition } from '@headlessui/react'
import createPortal from '@utils/createPortal'
import { defaultModifiers } from '@utils/popper'
import classNames from 'classnames'
import React, { ReactNode } from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'
import { Config } from 'react-popper-tooltip/dist/types'

interface ITooltipProps {
  content: ReactNode
  children: any
  containerTag?: string
  containerClassName?: string
  paddingClass?: string
  placement?: Config['placement']
  interactive?: Config['interactive']
  zIndex?: string
  followCursor?: Config['followCursor']
  delayShow?: Config['delayShow']
}

const Tooltip = ({
  content,
  children,
  containerTag = 'span',
  containerClassName,
  paddingClass = 'px-3 py-2',
  placement,
  interactive,
  followCursor,
  zIndex = 'z-50',
  delayShow = 0,
}: ITooltipProps) => {
  const {
    getTooltipProps,
    getArrowProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip(
    {
      placement,
      interactive,
      followCursor,
      delayShow,
    },
    {
      modifiers: defaultModifiers,
    },
  )

  const Container = containerTag as any

  return (
    <>
      <Container ref={setTriggerRef} className={containerClassName}>
        {children}
      </Container>
      {visible &&
        createPortal(
          <div
            ref={setTooltipRef}
            {...getTooltipProps({
              className: classNames('tooltip-container', zIndex),
            })}
          >
            <Transition
              show
              appear
              enter="transition duration-100 ease-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div {...getArrowProps({ className: 'tooltip-arrow' })} />
              <div
                className={classNames([
                  'bg-white shadow-popup rounded-md text-oxford-gray-800 text-xs',
                  paddingClass,
                ])}
              >
                {content}
              </div>
            </Transition>
          </div>,
        )}
    </>
  )
}

export default Tooltip
