import React, { useCallback, useEffect, useState } from 'react'
import { Listbox } from '@headlessui/react'
import { FormikErrors } from 'formik'
import { FormError } from '@components/Ui/FormError'
import DropdownList from './List/DropdownList'
import DropdownButton from './Button/DropdownButton'
import classNames from 'classnames'
import { usePopper } from 'react-popper'
import ControlDataLoader, {
  Noop,
} from '@components/Shared/entityControl/ControlDataLoader'

export interface IDropdown {
  id?: string
  title?: string
  name?: string
  placeholder?: string
  textField?: string
  valueField?: string
  className?: string
  data?: any[]
  loading?: boolean
  disabled?: boolean
  defaultOpen?: boolean
  inline?: boolean
  value?: any
  required?: boolean
  cellEditor?: boolean
  listHeight?: string
  paddingClass?: string
  DataLoader?: any
  queryVariables?: Record<any, any>
  allowCreate?: 'onFilter' | false
  allowSearch?: boolean
  dataParser?: (data: any[]) => any[]
  onCreate?: (item: any) => void
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[]
  onChange: (value: any) => void
  onBlur?: (e?: any) => void
  renderClearButton?: ((onClear: () => void) => React.ReactElement) | false
  renderListTop?: () => React.ReactElement
  renderItem?: ({
    item,
    inButton,
  }: {
    item: any
    inButton: boolean
  }) => React.ReactElement
  renderButtonContent?: ({
    item,
    placeholder,
  }: {
    item?: any
    placeholder?: string
  }) => React.ReactElement
}

const Dropdown = (props: IDropdown) => {
  const {
    data = [],
    loading,
    disabled,
    value,
    title,
    defaultOpen,
    required,
    onCreate,
    DataLoader = Noop,
    queryVariables,
    error = null,
    onChange,
  } = props
  const propsLoading = loading
  const propsData = data

  const [buttonElement, setButtonElement] = useState<HTMLDivElement>()
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>()
  const [popperElement, setPopperElement] = useState<HTMLDivElement>()

  const {
    state: popperState,
    styles: popperStyles,
    attributes: popperAttributes,
  } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 1],
        },
      },
    ],
  })

  useEffect(() => {
    if (defaultOpen && buttonElement) {
      buttonElement.click()
    }
  }, [defaultOpen, buttonElement])

  const onListboxChange = useCallback(
    (value) => {
      if (value.create) {
        // when create 'option' is selected
        const { create, ...rest } = value

        onCreate(rest)
      } else {
        onChange(value)
      }
    },
    [onCreate, onChange],
  )

  return (
    <React.Fragment>
      <Listbox disabled={disabled} value={value} onChange={onListboxChange}>
        {({ open }) => (
          <ControlDataLoader
            DataLoader={DataLoader}
            open={open}
            variables={queryVariables}
            fetchPolicy="cache-and-network"
          >
            {(
              { data, loading } = {
                data: propsData,
                loading: propsLoading,
              },
            ) => (
              <div className="relative">
                {title && (
                  <Listbox.Label
                    className={classNames([
                      'block relative text-sm font-bold text-oxford-gray-800 pb-2',
                      {
                        required,
                      },
                    ])}
                  >
                    {title}
                  </Listbox.Label>
                )}
                <div ref={setReferenceElement}>
                  <DropdownButton
                    {...props}
                    open={open}
                    loading={loading}
                    popperPlacement={popperState?.placement ?? null}
                    setButtonElement={setButtonElement}
                  />
                </div>

                <DropdownList
                  {...props}
                  data={data}
                  loading={loading}
                  setPopperElement={setPopperElement}
                  popperStyles={popperStyles}
                  popperAttributes={popperAttributes}
                  popperPlacement={popperState?.placement ?? null}
                />
              </div>
            )}
          </ControlDataLoader>
        )}
      </Listbox>
      {error && <FormError error={error} />}
    </React.Fragment>
  )
}

export default Dropdown
