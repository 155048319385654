import gql from 'graphql-tag'

export const CONTACT_DETAILS_FRAGMENT = gql`
  fragment ContactDetailsFragment on ContactDetails {
    phone
    email
    notes
    address
    vat
  }
`

export const CLIENT_RESPONSE_FRAGMENT = gql`
  fragment ClientResponseFragment on Client {
    id
    isActive
    name
    contactDetails {
      ...ContactDetailsFragment
    }
  }
  ${CONTACT_DETAILS_FRAGMENT}
`

export const CONTACT_FRAGMENT = gql`
  fragment ContactFragment on Contact {
    id
    firstName
    lastName
    jobTitle
    contactDetails {
      ...ContactDetailsFragment
    }
  }
  ${CONTACT_DETAILS_FRAGMENT}
`

export const CLIENT_RESPONSE_FULL_FRAGMENT = gql`
  fragment ClientResponseFullFragment on Client {
    ...ClientResponseFragment
    contacts {
      ...ContactFragment
    }
  }
  ${CLIENT_RESPONSE_FRAGMENT}
  ${CONTACT_FRAGMENT}
`

export const CLIENT_FRAGMENT = gql`
  fragment ClientFragment on Client {
    id
    name
  }
`

export const PRODUCER_FRAGMENT = gql`
  fragment ProducerFragment on Producer {
    id
    isActive
    name
    contactDetails {
      ...ContactDetailsFragment
    }
  }
  ${CONTACT_DETAILS_FRAGMENT}
`

export const PRODUCER_RESPONSE_FULL_FRAGMENT = gql`
  fragment ProducerResponseFullFragment on Producer {
    ...ProducerFragment
    contacts {
      ...ContactFragment
    }
  }
  ${PRODUCER_FRAGMENT}
  ${CONTACT_FRAGMENT}
`

export const PROJECT_TYPE_FRAGMENT = gql`
  fragment ProjectTypeFragment on ProjectType {
    id
    name
    isActive
  }
`

export const TIME_FACTOR_FRAGMENT = gql`
  fragment TimeFactorFragment on TimeFactor {
    id
    name
    isActive
  }
`

export const JOB_TITLE_FRAGMENT = gql`
  fragment JobTitleFragment on JobTitle {
    id
    name
    isActive
  }
`

export const SIZE_FRAGMENT = gql`
  fragment SizeFragment on Size {
    id
    isActive
    name
  }
`

export const SIZE_STANDARD_FRAGMENT = gql`
  fragment SizeStandardFragment on SizeStandard {
    id
    name
    isActive
    sizes {
      ...SizeFragment
    }
  }
  ${SIZE_FRAGMENT}
`

export const ROLE_FRAGMENT = gql`
  fragment RoleFragment on Role {
    id
    name
    description
  }
`

export const TEAM_WITH_TEAM_LEAD_ID_FRAGMENT = gql`
  fragment TeamNameWithTeamLeadIdFragment on Team {
    id
    name
    teamLead {
      id
    }
  }
`

export const ACCOUNT_FRAGMENT = gql`
  fragment AccountFragment on Account {
    id
    isActive
    firstName
    lastName
    fullName
    email
    jobTitle {
      id
      name
    }
    mobilePhone
    lang
    datePreferences
    timezone
    playThreadNotificationSound
    showOnlyNewThreadsActivity
  }
`

export const ACCOUNT_PROFILE_IMAGE_FRAGMENT = gql`
  fragment AccountProfileImageFragment on Account {
    id
    profileImage {
      id
      presignedUrl @nonreactive
    }
  }
`

export const INVITATION_FRAGMENT = gql`
  fragment InvitationFragment on Invitation {
    status
    id
    inviteeEmail
    createdAt {
      seconds
    }
    expiresAt {
      seconds
    }
    inviter {
      ...AccountFragment
    }
    roles {
      ...RoleFragment
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${ROLE_FRAGMENT}
`

export const BASE_MILESTONE_FRAGMENT = gql`
  fragment BaseMilestoneFragment on BaseMilestone {
    id
    name
    isActive
  }
`

export const TEAM_MEMBER_FRAGMENT = gql`
  fragment TeamMemberFragment on TeamMember {
    account {
      ...AccountFragment
    }
    isGuest
  }
  ${ACCOUNT_FRAGMENT}
`

export const TEAM_FRAGMENT = gql`
  fragment TeamFragment on Team {
    id
    isActive
    name
    teamLead {
      ...AccountFragment
    }
    members {
      ...TeamMemberFragment
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${TEAM_MEMBER_FRAGMENT}
`

export const LABEL_FRAGMENT = gql`
  fragment LabelFragment on Label {
    isActive
    id
    name
    color
  }
`
export const PROCEDURE_TASK_FRAGMENT = gql`
  fragment ProcedureTaskFragment on ProcedureTask {
    id
    name
    description
  }
`

export const PROCEDURE_MILESTONE_FRAGMENT = gql`
  fragment ProcedureMilestoneFragment on ProcedureMilestone {
    id
    name
    position
    procedureId
    baseMilestoneId
    duration
    tasks {
      ...ProcedureTaskFragment
    }
  }
  ${PROCEDURE_TASK_FRAGMENT}
`

export const PROCEDURE_MILESTONE_FULL_FRAGMENT = gql`
  fragment ProcedureMilestoneFullFragment on ProcedureMilestone {
    id
    name
    position
    procedureId
    baseMilestoneId
    milestoneDependencyIds
    label {
      ...LabelFragment
    }
  }
  ${LABEL_FRAGMENT}
`

export const PROCEDURE_MILESTONE_LABEL_AND_DEPENDENCIES_FRAGMENT = gql`
  fragment ProcedureMilestoneLabelAndDependenciesFragment on ProcedureMilestone {
    id
    milestoneDependencyIds
    label {
      ...LabelFragment
    }
  }
  ${LABEL_FRAGMENT}
`

export const PROCEDURE_WITH_PARTIAL_MILESTONES_FRAGMENT = gql`
  fragment ProcedureWithPartialMilestonesFragment on Procedure {
    id
    milestones {
      ...ProcedureMilestoneLabelAndDependenciesFragment
    }
  }
  ${PROCEDURE_MILESTONE_LABEL_AND_DEPENDENCIES_FRAGMENT}
`

export const PROCEDURE_FRAGMENT = gql`
  fragment ProcedureFragment on Procedure {
    id
    title
    notes
    isActive
    milestones {
      ...ProcedureMilestoneFragment
    }
  }
  ${PROCEDURE_MILESTONE_FRAGMENT}
`

export const UPDATE_PROCEDURE_FRAGMENT = gql`
  fragment UpdateProcedureFragment on Procedure {
    id
    title
    notes
    isActive
  }
`

export const CATEGORY_FRAGMENT = gql`
  fragment CategoryFragment on Category {
    id
    name
    isActive
  }
`

export const SUPPLIER_FRAGMENT = gql`
  fragment SupplierFragment on Supplier {
    id
    name
    isActive
  }
`

export const MANUFACTURER_FRAGMENT = gql`
  fragment ManufacturerFragment on Manufacturer {
    id
    name
    isActive
  }
`

export const MANUFACTURER_FULL_FRAGMENT = gql`
  fragment ManufacturerFullFragment on Manufacturer {
    ...ManufacturerFragment
    contactDetails {
      ...ContactDetailsFragment
    }
    contacts {
      ...ContactFragment
    }
  }
`

export const SUPPLIER_FULL_FRAGMENT = gql`
  fragment SupplierFullFragment on Supplier {
    ...SupplierFragment
    contactDetails {
      ...ContactDetailsFragment
    }
    contacts {
      ...ContactFragment
    }
  }
`

export const RESOURCE_FRAGMENT = gql`
  fragment ResourceFragment on Resource {
    id
    project {
      id
      access
    }
    milestone {
      id
    }
    task {
      id
    }
    isInline
    key
    commentId
    contextType
    contentType
    attachmentType
    presignedUrl
    access
    userAccess
    name
    alias
    size
    createdAt {
      seconds
    }
    account {
      ...AccountFragment
    }
    category {
      ...CategoryFragment
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${CATEGORY_FRAGMENT}
`
export const USER_CONTRIBUTOR_FRAGMENT = gql`
  fragment UserContributorFragment on UserContributor {
    access
    isProjectResponsible
    account {
      ...AccountFragment
    }
  }
  ${ACCOUNT_FRAGMENT}
`

export const MATERIAL_TYPE_FRAGMENT = gql`
  fragment MaterialTypeFragment on MaterialType {
    id
    name
    isActive
  }
`

export const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on Address {
    id
    isActive
    name
    companyName
    vat
    contactPerson
    country
    region
    city
    postCode
    addressLine1
    addressLine2
    phone
    email
  }
`

export const RESOURCE_WITH_ENTITY_NAMES_FRAGMENT = gql`
  fragment ResourceWithEntityNamesFragment on Resource {
    id
    project {
      id
      name
    }
    milestone {
      id
      name
    }
    task {
      id
      name
    }
    commentId
    contextType
    contentType
    attachmentType
    presignedUrl
    access
    userAccess
    name
    alias
    size
    createdAt {
      seconds
    }
    account {
      ...AccountFragment
    }
    category {
      ...CategoryFragment
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${CATEGORY_FRAGMENT}
`

export const RESOURCE_ACCESS_FRAGMENT = gql`
  fragment ResourceAccessFragment on Resource {
    id
    access
    teamContributors {
      team {
        ...TeamFragment
      }
      contributors {
        ...UserContributorFragment
      }
    }
    userContributors {
      ...UserContributorFragment
    }
  }
  ${USER_CONTRIBUTOR_FRAGMENT}
  ${TEAM_FRAGMENT}
`

export const DELETE_RESOURCE_FRAGMENT = gql`
  fragment DeleteResourceFragment on DeleteResource {
    failedIds
  }
`
export const CALENDAR_FRAGMENT = gql`
  fragment CalendarFragment on Calendar {
    id
    name
    type
    isSelected
  }
`

export const CALENDAR_EVENT_FRAGMENT = gql`
  fragment CalendarEventFragment on CalendarEvent {
    fromDate {
      seconds
    }
    toDate {
      seconds
    }
    fromTime {
      seconds
    }
    toTime {
      seconds
    }
    link
    meetingLink
    name
  }
`

export const ORGANIZATION_FRAGMENT = gql`
  fragment OrganizationFragment on Organization {
    id
    name
    currency
    language
    workDays
    workDayHours
    workCalendarExceptionPeriods {
      ...WorkCalendarExceptionPeriodFragment
    }
    createdAt {
      seconds
    }
    logo {
      id
      presignedUrl
    }
  }
`

export const ORGANIZATION_FULL_FRAGMENT = gql`
  fragment OrganizationFullFragment on Organization {
    id
    providerId
    name
    enabledFeatures
    createdAt {
      seconds
    }
    currency
    isActive
    language
    industry
    logo {
      ...ResourceFragment
    }
    notes
    type
    updatedAt {
      seconds
    }
  }
  ${RESOURCE_FRAGMENT}
`

export const WORK_CALENDAR_EXCEPTION_PERIOD_FRAGMENT = gql`
  fragment WorkCalendarExceptionPeriodFragment on WorkCalendarExceptionPeriod {
    id
    type
    notes
    startDate {
      seconds
    }
    endDate {
      seconds
    }
  }
`
