import Popover from '@components/Shared/Popover/Popover'
import { Button } from '@components/Ui'
import { BUTTON_SIZE, BUTTON_TYPE } from '@components/Ui/Button/Button'
import DropdownMenu from '@components/Ui/DropdownMenu'
import ListGroup, {
  LIST_GROUP_POSITION,
} from '@components/Ui/ListGroup/ListGroup'
import classNames from 'classnames'

interface IActionItem {
  id: any
  label: any
  action: Function
}

interface IContributorMoreActions {
  actionItems: IActionItem[]
  disabled?: boolean
}

const ContributorMoreActions = ({
  actionItems,
  disabled = false,
}: IContributorMoreActions) => {
  const getItems = () =>
    actionItems.map(({ id, label, action }, index) => ({
      id: id + index,
      label,
      onClick: () => {
        action()
      },
      disabled,
    }))

  return (
    <DropdownMenu
      renderLabel={() => (
        <i
          className={classNames([
            'far fa-ellipsis text-2xl px-3',
            {
              'text-primary': !disabled,
              'text-oxford-gray-300': disabled,
            },
          ])}
        />
      )}
      items={getItems()}
      popperPlacement="right"
    />
  )
}

export default ContributorMoreActions
